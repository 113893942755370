import React from "react";

import { bem, getMilliseconds } from "../../utils";
import { SupervisorBadge, Countdown } from "../../components";
import { supervisorsData, START_TIMESTAMP } from "./constants";
import "./Year.scss";

const classNames = bem("year");

export const Year = () => (
    <div className={classNames()}>
        <h1>Letos</h1>
        <div className={classNames("panel")}>
            <h2>Základní informace</h2>
            <div className={classNames("row")}>
                <div className={classNames("left-part")}>
                    Téma<br />
                    Datum konání
                </div>
                <div>
                    Dobrodružství Hereditas – výprava do minulosti<br />
                    20.–27. 7. 2025
                </div>
            </div>
        </div>
        <div className={classNames("panel")} style={{ textAlign: "justify" }}>
            Dámy a pánové, vítejte! Vítáme vás na našem zámku Tempora, tajemném sídle, které stojí už tisíce let a ukrývá mnohá tajemství.
            Na první pohled se možná zdá, že naše rodina je pouze stínem někdejší slávy, ale pravda je jiná. Pocházíme z rodu, jehož jméno
            bylo ve své době symbolem moci a úcty. I když nás poslední události přivedly na pokraj zkázy, osud nám nyní nabízí jedinečnou
            příležitost – získat zpět to, co jsme ztratili. Nejedná se ale o úkol, který bychom mohli zvládnout sami. Proto se obracíme právě
            na vás. Jste připraveni na tajuplnou výpravu, která vás zavede až k odkazu Hereditase I., zakladatele našeho rodu, a odhalí tajemství,
            která zůstala skrytá po staletí?
        </div>
        {START_TIMESTAMP > getMilliseconds() ? (
            <div className={classNames("panel")}>
                <div className={classNames("row")}>
                    <span className={classNames("countdown-description")}>Začínáme za</span>
                    <Countdown countdownTimestamp={START_TIMESTAMP} />
                </div>
            </div>
        ) : null}
        <div className={classNames("panel")}>
            <h2>Stav přihlášek</h2>
            <div className={classNames("row")}>
                Přihlašování již brzy
            </div>
        </div>
        <div className={classNames("panel")}>
            <h2>Vedoucí</h2>
            {supervisorsData.map((supervisor) => (
                <SupervisorBadge
                    key={supervisor.id}
                    {...supervisor}
                />
            ))}
        </div>
    </div>
);
